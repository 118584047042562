._cardImage {
  width: 50%;
  margin: auto;
}

._cardMainDiv {
  text-align: center;
  background-color: white;
  margin: auto;
  width: 100%;
  padding: 10%;
}

._cardDiv1 {
  padding: 1%;
  text-align: center;
  background-color: white;
  margin: 10px;
  height: 350px;
  box-shadow: 3px 3px 10px 5px rgb(165, 163, 163);
  border-radius: 10px;
}

.backtolost {
  background-color: #422a5f;
  border: 1px solid #422a5f;
  padding: 3%;
  color: white;
  width: 50%;
  display: block;
  align-self: center;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 5px;
}
.deletebtn {
  background-color: #422a5f;
  border: 1px solid #422a5f;
  padding: 3%;
  color: white;
  width: 50%;
  display: block;
  align-self: center;
  border-radius: 5px;
  margin: auto;
}

.btnDiv {
  flex-direction: column;
}
