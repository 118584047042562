._container {
  box-shadow: 4px 4px 4px 2px rgb(224, 218, 218);
  width: 60%;
  margin: auto;
  margin-top: 9vh;
  margin-bottom: 50px;
  padding: 20px;
  background-color: #422a5f;
}
._Name {
  font-size: 12px;
  line-height: 22px;
  color: white;
  /* font-weight: normal; */
  display: block;
}
