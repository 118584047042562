._cardImage {
  width: 50%;
  margin: auto;
}

._cardMainDiv {
  text-align: center;
  background-color: white;
  margin: auto;
  width: 100%;
  padding: 10%;
}

._cardDiv {
  padding: 1%;
  text-align: center;
  background-color: white;
  margin: 10px;
  height: 330px;
  box-shadow: 3px 3px 10px 5px rgb(165, 163, 163);
  border-radius: 10px;
}

._seeMoreBtn {
  background-color: #422a5f;
  border: 1px solid #422a5f;
  padding: 3%;
  color: white;
  width: 50%;
  border-radius: 5px;
}
