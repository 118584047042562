._cardDi {
  text-align: center;
  background-color: white;
  height: 180px;
  align-content: center;
  align-items: center;
  padding-top: 10%;
  width: 90%;
  margin-top: 5%;
  margin-left: 5%;
  box-shadow: 3px 3px 10px 5px rgb(165, 163, 163);
  border-radius: 10px;
}

.title {
  font-size: 25px;
  font-weight: bold;
}
.amount {
  font-size: 30px;
}
